import store from "../store"

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  const token = store.getters.getUserToken
  if (token) headers.token = token
  return { headers }
}

export default axios => ({
  async getBundledIn(productId) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/products/contained_in_bundle/' + productId, headers())
    } catch (e) {
      return e.response
    }
  },
  async getProductDetail(id, count) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/products/" + id +
        (count === false ? '?productViewCount=false' : '')
        , headers())
    } catch (e) {
      return e.response
    }
  },
  async getProductList(offset, limit, sort, filter, home, bp_exclusive) {
    try {
      const obj = { offset, limit }
      if (sort) obj.sort = encodeURIComponent(JSON.stringify(sort))
      if (filter) obj.filter = encodeURIComponent(JSON.stringify(filter))
      if (home) obj.home = "true"
      if (bp_exclusive != undefined) obj.bp_exclusive = bp_exclusive;
      const query = []
      for (let o in obj) {
        query.push(o + "=" + obj[o])
      }
      return await axios.get(process.env.VUE_APP_API_URL + "users/products/search_v3?" + query.join('&'), headers())
    } catch (e) {
      return e.response
    }
  },
  async getLatestToast() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/products/toast', headers())
    } catch (e) {
      return e.response
    }
  },
  async getInstallmentCalculator(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `users/products/calculate_instalment/${id}`, headers())
    } catch (e) {
      return e.response
    }
  },
  async getProductReviewList(id, params = {}) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `users/products/review/${id}`, { params, ...headers() })
    } catch (e) {
      return e.response
    }
  },
  async getProductReviewProfileList(offset, limit) {
    try {
      const obj = { offset, limit }
      const query = []
      for (let o in obj) {
        if (obj[o] != null) query.push(o + "=" + obj[o])
      }
      return await axios.get(process.env.VUE_APP_API_URL + "users/productreview/lists/reviewed" + (query.length ? `?${query.join('&')}` : ""), headers())
    } catch (e) {
      return e.response
    }
  },
  async getUnreviewedProductProfileList(offset, limit) {
    try {
      const obj = { offset, limit }
      const query = []
      for (let o in obj) {
        if (obj[o] != null) query.push(o + "=" + obj[o])
      }
      return await axios.get(process.env.VUE_APP_API_URL + "users/productreview/lists/unreviewed" + (query.length ? `?${query.join('&')}` : ""), headers())
    } catch (e) {
      return e.response
    }
  },
})
