import store from "../store";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType,
  }
  const token = store.getters.getGuestToken ?? store.getters.getUserToken;
  if (token) headers.token = token
  return { headers }
}

export default axios => ({
  // Generate default invoice (for BP / Customer)
  async getDefaultInvoice(id) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + `users/order/download_invoice/${id}`, {}, headers())
    } catch (e) {
      return e.response
    }
  },
  // Generate invoice for customer by Bus. Partner (discount / no discount)
  async getResellerInvoice(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + `users/order/export_invoice`, data, headers())
    } catch (e) {
      return e.response
    }
  },
  async getOrderDetailGeneral(orderNumber, tries = 1) {
    // Give up trying to get order detail general if past 10 tries and still no token
    if (!headers().headers.token && tries < 10)
      return await new Promise(resolve => setTimeout(() => resolve(this.getOrderDetailGeneral(orderNumber, ++tries)), 100))

    try {
      return await axios.get(process.env.VUE_APP_API_URL + `users/order/detail_ordernumbergeneral/${orderNumber}`, headers())
    } catch (e) {
      return e.response
    }
  },
  //* Get order detail with orderNumber format like '1M1-HSOxxxxxx'
  async getOrderDetail(orderNumber) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `users/order/detail_ordernumber/${orderNumber}`, headers())
    } catch (e) {
      return e.response
    }
  },
  async getOrderList(type, offset, limit) {
    try {
      const obj = { offset, limit }
      const query = []
      for (let o in obj) {
        if (obj[o] != null) query.push(o + "=" + obj[o])
      }
      return await axios.get(process.env.VUE_APP_API_URL + `users/order/lists?type=${type}` + (query.length ? `?${query.join('&')}` : ""), headers())
    } catch (e) {
      return e.response
    }
  },
  async cancelOrder(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + `users/order/cancelorder`, data, headers())
    } catch (e) {
      return e.response
    }
  },
  async cancelAllOrder(type) {
    try {
      return await axios.delete(process.env.VUE_APP_API_URL + `users/order/cancelorder_all?type=${type}`, headers())
    } catch (e) {
      return e.response
    }
  },
  async getDetailVoucher(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `users/order/voucher_detail/${id}`, headers())
    } catch (e) {
      return e.response
    }
  },
})
