// import firebase from "firebase/compat/app";
// import { getMessaging, getToken, isSupported } from "firebase/messaging";
import store from "../store";

const headers = (authorization, contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  const token = store.getters.getUserToken
  if (token) headers.token = token
  return { headers }
}
// TODO we can use firebase.js to subscribe and unsub firebase cloud messaging
export default axios => ({
  sendFCM(messageField) {
    try {
      const list_topic = store.getters.getCurrentDialog.occupants_ids.reduce((acc, curVal) => {
        if (curVal != store.getters.getUserData.quickblox_id) acc.push(`qb-${curVal}`)
        return acc;
      }, []);

      const data = {
        list_topic,
        title: store.getters.getUserData.full_name,
        body: messageField,
        screenMobile: "/livechat",
        click_action: "https://merchant.whizliz.com/livechat"
      }

      axios.post(process.env.VUE_APP_API_URL + "users/fcm/send", data, headers());
    } catch (e) {
      console.error(e)
    }
  },
  async subscribeFCM(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "support/fcm/subscribe", data, headers())
    } catch (e) {
      return e.response
    }
  },
  async unsubscribeFCM(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "support/fcm/unsubscribe", data, headers())
    } catch (e) {
      return e.response
    }
  },

})
