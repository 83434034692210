import store from "../store"

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  const token = store.getters.getUserToken
  if (token) headers.token = token
  return { headers }
}

export default axios => ({
  async checkReseller(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `users/reseller/check/${id}`, headers())
    } catch (e) {
      return e.response;
    }
  },
  async getMembership(level) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/reseller/membership' + (level ? "?level=" + level : ''), headers())
    } catch (e) {
      return e.response
    }
  },
  async getAllMembership() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/reseller/membership_all', headers())
    } catch (e) {
      return e.response
    }
  },
  async getTaskProgress() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/reseller/membership_task_progress', headers())
    } catch (e) {
      return e.response
    }
  },
  async signUpReseller(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/session/signup_reseller_membership", data, headers("multipart/form-data"))
    } catch (e) {
      return e.response
    }
  },
  // BP Task
  async getTask(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/activity/take/' + id, headers())
    } catch (e) {
      return e.response
    }
  },
  async getMyTasks() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/activity/my_task', headers())
    } catch (e) {
      return e.response
    }
  },
  async getAvailableTask() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/activity/available_task', headers())
    } catch (e) {
      return e.response
    }
  },
  // BP Cashout
  async requestCashout(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/reseller/cashout", data, headers())
    } catch (e) {
      return e.response
    }
  },
  async cancelCashout(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/reseller/delete_cashout", data, headers())
    } catch (e) {
      return e.response
    }
  },
  async getCashoutRequestList(offset, limit) {
    try {
      const obj = { offset, limit }
      const query = []
      for (let o in obj) {
        if (obj[o] != null) query.push(o + "=" + obj[o])
      }
      return await axios.get(process.env.VUE_APP_API_URL + 'users/reseller/cashout_request' + (query.length ? `?${query.join('&')}` : ""), headers())
    } catch (e) {
      return e.response
    }
  },
  async getCommissionList(offset, limit) {
    try {
      const obj = { offset, limit }
      const query = []
      for (let o in obj) {
        if (obj[o] != null) query.push(o + "=" + obj[o])
      }
      return await axios.get(process.env.VUE_APP_API_URL + 'users/reseller/commission' + (query.length ? `?${query.join('&')}` : ""), headers())
    } catch (e) {
      return e.response
    }
  },
  async getCommissionTotal() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/reseller/commission_total', headers())
    } catch (e) {
      return e.response
    }
  },
  //* Get 'reseller' list which 'show' column is TRUE (For Find Our BP Feature)
  async getShowResellerList(params) {
    try {
      return await axios.get(
        process.env.VUE_APP_API_URL + "users/reseller/list", 
        {
          params: params,
          ...headers()
        }
      );
    } catch (e) {
      return e.response
    }
  },
  async getFaqCustomerReseller() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/faq_customer_reseller', headers())
    } catch (e) {
      return e.response
    }
  },
  async getFaqReseller() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/faq_reseller', headers())
    } catch (e) {
      return e.response
    }
  },
  async getShowResellerTownshipList(params) {
    try {
      return await axios.get(
        process.env.VUE_APP_API_URL + "users/reseller/list_township",
        {
          params: params,
          ...headers()
        }
      );
    } catch (e) {
      return e.response;
    }
  },
})
