import store from '../store';
import { cookieMan } from '../_helpers';

const headers = (contentType = 'application/json') => ({
  headers: {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType,
    token: store.getters.getUserToken ?? store.getters.getGuestToken
  }
})

export default axios => ({
  async checkValidPayment() {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + 'users/order/get_valid_payment', {}, headers())
    } catch (e) {
      return e.response
    }
  },
  async getPickupPoint(productId) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/order/pickuppoint/list_by_product/' + productId, headers())
    } catch (e) {
      return e.response
    }
  },
  async calculateShipping(subdistrictId, cityId, weight, courierCode) {
    const body = {
      origin: parseInt(subdistrictId.trim()),
      destination: parseInt(cityId),
      weight: weight,
      courier: courierCode,
    }
    try {
      return await axios.post(process.env.VUE_APP_API_URL + 'users/order/calculate_shipping_rongkir', body, headers())
    } catch (e) {
      return e.response
    }
  },
  async getVouchers(checkoutData) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + 'users/order/get_valid_voucher', checkoutData, headers())
    } catch (e) {
      return e.response;
    }
  },
  async getPayments(checkoutData) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + 'users/order/get_valid_payment', checkoutData, headers())
    } catch (e) {
      return e.response;
    }
  },
  async saveOrder(checkoutData) {
    try {
      const endpoint = `users/order/${store.getters.getGuestToken ? 'q_' : ''}save_order`,
        res = await axios.post(process.env.VUE_APP_API_URL + endpoint, checkoutData, headers('multipart/form-data'))
        if (res.status === 200) cookieMan.put('redir_order_number', res.data.data.order_number);
      return res
    } catch (e) {
      return e.response;
    }
  }
})
