import Vue from "vue";
import axios from "axios";
import CMS from "@/api/cms";
import Product from "@/api/product";
import Order from "@/api/order";
import User from "@/api/user";
import Reseller from "@/api/reseller";
import Checkout from "./api/checkout";
import Catalog from "@/api/catalog";
import Firebase from "@/api/firebase";

export const factories = {
  cms: CMS(axios),
  product: Product(axios),
  order: Order(axios),
  user: User(axios),
  reseller: Reseller(axios),
  checkout: Checkout(axios),
  catalog: Catalog(axios),
  firebase: Firebase(axios),
}

Vue.prototype.$api = factories
