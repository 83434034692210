import Vue from "vue";
import store from "../store";
import { cookieMan, userHelper } from "@/_helpers";
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  const token = store.getters.getUserToken
  if (token) headers.token = token
  return { headers }
}

export default axios => ({
  async deactivateAccount(code) {
    try {
      return await code
        ? axios.delete(process.env.VUE_APP_API_URL + 'users/session/confirm_delete', Object.assign(headers(), {data: {code}}))
        : axios.get(process.env.VUE_APP_API_URL + 'users/session/delete', headers())
    } catch (e) {
      return e.response
    }
  },
  async checkMember(email) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + 'users/session/check_member', { email }, headers())
    } catch (e) {
      return e.response;
    }
  },
  async signinGoogleOneTap(jwt) {
    try {
      const res = await axios.post(process.env.VUE_APP_API_URL + 'users/session/login/one_tap', { jwt, device_model: 'WEB', device_type: 'WEB'}, headers())
      const { data } = res.data
      localStorage.setItem("token", data.access_token)
      store.dispatch('setUserToken', data.access_token)
      store.dispatch('setUserData', data.profile_data)
      store.dispatch('setResellerData', data.reseller_data)
      userHelper.setGoogleOneTapCooldown(10)
      firebase.analytics().logEvent("login")
      return res
    } catch (e) {
      return e.response;
    }
  },
  async subscribeWebPush(token) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/session/subscribe_web_push/' + token, headers())
    } catch (e) {
      return e.response;
    }
  },
  async quickCheckout(variantId, quantity, bundle) {
    const body = {
      product_detail_variance_id: variantId,
      quantity: quantity,
      temp_session: cookieMan.get('fe_guest_session') ?? '',
      device_model: 'WEB',
      device_type: 'WEB',
      device_id: '',
    }
    if (bundle) body.bundle_product = bundle
    try {
      const res = await axios.post(process.env.VUE_APP_API_URL + "users/bag/q_add", body, headers())
      if (res.status === 200) {
        const { data } = res.data
        if (!store.getters.getUserData) {
          cookieMan.put('fe_guest_session', data.temp_session)
          cookieMan.put('fe_guest_token', data.token)
          store.dispatch('setGuestSession', data.temp_session)
          store.dispatch('setGuestToken', data.token)
        }
        // TODO firebase analytics if needed
      }
      return res
    } catch (e) {
      return e.response
    }
  },
  async addToCart(variantId, quantity, bundle) {
    const body = {
      product_detail_variance_id: variantId,
      quantity: quantity,
    }
    if (bundle) body.bundle_product = bundle
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/bag/add", body, headers())
    } catch (e) {
      return e.response
    }
  },
  async getCart() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/bag/list", headers())
    } catch (e) {
      return e.response
    }
  },
  async addToWishlist(product) {
    try {
      const res = await axios.post(process.env.VUE_APP_API_URL + "users/products/wishlist", {
        product_id: product.id
      }, headers())
      firebase.analytics().logEvent("add_to_wishlist", {
        currency: "IDR",
        items: [{
          item_id: product.id.toString(),
          item_name: product.name,
          item_brand: product.brand_name,
          item_category: product.category3_name,
          price: product.price_display,
          size: null,
          currency: "IDR",
        }],
        value: product.price_display,
      })
      if (process.env.VUE_APP_API_URL.indexOf('staging') < 0)
        Vue.analytics.fbq.event("AddToWishlist", {
          content_ids: product.id,
          content_type: "product",
          content_name: product.name,
          content_category: product.category3_name,
          value: product.price_display,
          currency: "IDR",
        })
      return res
    } catch (e) {
      return e.response;
    }
  },
  async removeFromWishlist(productId) {
    try {
      return await axios.delete(process.env.VUE_APP_API_URL + "users/products/wishlist/" + productId, headers())
    } catch (e) {
      return e.response;
    }
  },
  async getWishlist() {
    try {
      const res = await axios.get(process.env.VUE_APP_API_URL + "users/products/wishlist/get", headers())
      store.dispatch("setWishlist", res.data.data)
      return res;
    } catch (e) {
      return e.response;
    }
  },
  async changePassword(data) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + "users/session/change_password", data, headers())
    } catch (e) {
      return e.response
    }
  },
  async getHotIcon() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/hoticon", headers())
    } catch (e) {
      return e.response
    }
  },
  // Product Review
  async getProductReviewBrand(params = {}) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/productreview/list_brand", { params, ...headers() } );
    } catch (e) {
      return e.response
    }
  },
  async getProductReviewTags() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/productreview/retrievetag", headers())
    } catch (e) {
      return e.response
    }
  },
  async saveProductReview(data){
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/productreview/save", data, headers("multipart/form-data"))
    } catch (e) {
      return e.response
    }
  },
  async saveProductReviewV2(data){
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/productreview/saveV2", data, headers("multipart/form-data"))
    } catch (e) {
      return e.response
    }
  },
  // Liz Magazine Article
  async getArticles() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `users/articles/all`, headers())
    } catch (e) {
      return e.response
    }
  },
  async getDetailArticle(slug) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `users/articles/slug/${slug}`, headers())
    } catch (e) {
      return e.response
    }
  },
  async getArticleComment(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `users/articles/${id}/comments`, headers())
    } catch (e) {
      return e.response
    }
  },
  async getArticleReplyComment(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `users/articles/comments/${id}/replies`, headers())
    } catch (e) {
      return e.response
    }
  },
  async submitArticleComment(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/articles/comments", data, headers())
    } catch (e) {
      return e.response
    }
  },
  async downloadProductBrochure(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/products/brochure", data, headers())
    } catch (e) {
      return e.response
    }
  },
  async subscribeEmail(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/session/subscribe", data, headers())
    } catch (e) {
      return e.response
    }
  },
  async forgotPassword(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/session/forgot_password", data, headers());
    } catch (e) {
      return e.response;
    }
  },
  async checkStatusResetPassword(data) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + "users/session/checkStatusResetPassowrd", data, headers());
    } catch (e) {
      return e.response;
    }
  },
  async resetPassword(data) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + "users/session/reset_password", data, headers());
    } catch (e) {
      return e.response;
    }
  },
  async setJuenePassword(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "support/migrate/forgetpassword", data, headers());
    } catch (e) {
      return e.response;
    }
  },
  async getProductReviewLizpointSetting() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/productreview/point_setting", headers())
    } catch (e) {
      return e.response
    }
  },
  async sendReport(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/contact_us/reported", data, headers("multipart/form-data"))
    } catch (e) {
      return e.response
    }
  }
})
