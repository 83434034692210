import store from "../store"

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  const token = store.getters.getUserToken
  if (token) headers.token = token
  return { headers }
}

export default axios => ({
  async getCatalogGroupList(id) {
    try {
      // if id is null use reseller token to get the response
      const route = id ? `users/reseller/product_group/${id}` : "users/reseller/product_group" 
      return await axios.get(process.env.VUE_APP_API_URL + route, headers())
    } catch (e) {
      return e.response
    }
  },
  async addCatalogGroup(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/reseller/product_group", data, headers())
    } catch (e) {
      return e.response
    }
  },
  async updateCatalogGroup(groupId, data) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + `users/reseller/product_group/${groupId}`, data, headers())
    } catch (e) {
      return e.response
    }
  },
  async deleteCatalogGroup(groupId) {
    try {
      return await axios.delete(process.env.VUE_APP_API_URL + `users/reseller/product_group/${groupId}`, headers())
    } catch (e) {
      return e.response
    }
  },
  async addProductToCatalogGroup(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/reseller/select_product_v2", data, headers())
    } catch (e) {
      return e.response
    }
  },
  async deleteProductFromCatalogGroup(data) {
    try {
      return await axios.post(process.env.VUE_APP_API_URL + "users/reseller/delete_select_product_v2", data, headers())
    } catch (e) {
      return e.response
    }
  },
  async getProductByCatalogGroupId(groupId, offset, limit) {
    try {
      const obj = { offset, limit }
      const query = []
      for (let o in obj) {
        if (obj[o] != null) query.push(o + "=" + obj[o])
      }
      return await axios.get(process.env.VUE_APP_API_URL + `users/reseller/product/${groupId}` + (query.length ? `?${query.join('&')}` : ""), headers())
    } catch (e) {
      return e.response
    }
  },
})